<template>
  <v-app>
    <v-progress-linear v-if="$store.state.progressLoading" height="2" indeterminate color="indigo" absolute
      style="top:0px;z-index:1000"></v-progress-linear>


    <div class="bg-design-box bg-design-2"></div>
    <div :class="`bg-design-box bg-design-3 bgcolor-${($route.meta || {}).color}-20`"></div>

    <div v-if="!clientload" class="all-height d-flex align-center justify-center">
      <div>
        <div class="project-title display-3 pb-5 text-center">
          <img src="/img/consark/logo.png" class="logo-image" alt="logo-image" />
        </div>
        <loader />
      </div>
    </div>
    <v-layout class="d-flex flex-column">
      <v-app-bar color="#F4F4FB" prominent height="50" max-height="50"
        class="border-on d-flex justify-space-between w-100">
        <div class="d-flex">
          <div style="height:44px" class="d-flex align-center justify-start py-4 px-4">
            <img width="70%" :aspect-ratio="16 / 9" src="/img/consark/logo.png" alt="back-ground image" />
          </div>
        </div>
        <v-spacer></v-spacer>
        <div class="d-flex">
          <div class="mr-2" v-if="($route.meta || {}).showclients !== false && showHeaderAction">
            <v-chip outlined label class="px-4" v-if="($store.state.clientlist || []).length === 1">
              {{ $store.state.clientlist[0].name }}
            </v-chip>
            <lb-dropdown v-else-if="($store.state.clientlist || []).length > 1"
              style="min-width: 200px; background:white" label="" prependinnericon="mdi-domain" appendicon="mdi-chevron-down"  :items="$store.state.clientlist" itemnamet="name"
              itemvalue="_id" v-model="selectedclient" hidedetails :clearable="false" />
          </div>
            <div class="d-flex align-center justify-center">
              <v-divider vertical class="px-3" ></v-divider>
              <v-btn icon small class="px-5 pl-10" >
                <v-icon style="font-size:20px !important; margin-top:-4px ">mdi-help-circle-outline</v-icon>
              </v-btn>
          </div>
          <v-menu transition="slide-y-transition" bottom :offset-y="true" v-if="showHeaderAction"  class="radius-medium " style="z-index:11">
            <template v-slot:activator="{ on, attrs }">
              <v-avatar colo="white" style="background:transparent; margin-top:-5px" icon v-bind="attrs" v-on="on">
                <img width="70%"  v-if="profileImage" :src="profileImage" alt="back-ground image" />
                <v-icon style="font-size:30px !important" v-else >mdi-account-circle-outline</v-icon>
              </v-avatar>
            </template>
            <v-list v-if="$route.path.includes('financialclose')" dense style="max-width: 36vh !important; background-color:#F4F4FB; " class="radius-medium pb-0">
              <v-list-item classd="d-flex align-center justify-space-between all-width">
                <v-spacer></v-spacer>
                <v-btn small icon><v-icon>mdi-close</v-icon></v-btn>
              </v-list-item>
              <v-list-item class="pb-2 ">
                <v-list-item-content class="text-center  all-width d-flex align-center justify-center">
                  <v-avatar size="20" color="caccounting" style="max-width:5vh ; height:5vh !important">
                    
                    <img  style="width:100%" v-if="profileImage" :src="profileImage" alt="back-ground image" />
                    <p v-else class="pa-0 ma-0 white--text  ">{{$store.state.user.name[0]}}</p>
                  </v-avatar>
                  <v-list-item-title class="py-1 mt-2 text-h6 font-weight-bold">
                    Hi {{ $store.state.user.name }}!
                  </v-list-item-title>

                  <v-list-item class="  d-flex align-center justify-center all-width mt-4">
                    <v-btn link @click="logout()" style="border:1px solid #6B67CE" class="mr-2 pa-0 ma-0 ">
                      <v-icon color="caccounting" size="10" dense class="px-1">mdi-logout-variant</v-icon>
                      <p class="caccounting--text text-body-2 ma-0 pa-0">Sign out</p>
                    </v-btn>
                    <v-btn color="caccounting" link @click="editProfileDrawer=!editProfileDrawer; editProfileSetting=false" class="ml-2 pa-0 ma-0 pr-2">
                      <v-icon color="white" dense size="10" class="px-1">mdi-pencil-outline</v-icon>
                      <p class="ma-0 pa-0 white--text text-body-2">Edit profile</p>
                    </v-btn>
                    
                  </v-list-item>
                </v-list-item-content>
              </v-list-item>
            </v-list>

            <v-list v-else dense style="max-width: 230px !important;" class="pb-0">
              <v-list-item class="pb-2">
                <v-list-item-content class="text-center">
                  <v-list-item-title class="py-1 subtitle-1">
                    {{ $store.state.user.name }}
                  </v-list-item-title>
                  <v-list-item-subtitle>{{ $store.state.user.email }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-divider></v-divider>
              <!-- App list -->
              <template v-if="applist.length > 1">
                <v-list-item>
                  <v-list-item-content class="text-center">
                    <div class="">
                      <template v-for="(v, k) in applist">
                        <v-tooltip bottom :key="k" content-class="tooltip-bottom">
                          <template v-slot:activator="{ on, attrs }">
                            <div v-bind="attrs" v-on="on"
                              class="text-center d-inline-block hoverable pa-2 cursor-pointer"
                              @click="$nova.gotoLink({ path: v.link })">
                              <v-avatar :class="`${v.color || 'primary'} rounded`" size="50" tile>
                                <img v-if="v.logo" :src="v.logo" :alt="v.logo" />
                                <span v-else class="white--text text-h5">{{ v.avatartext || "-" }}</span>
                              </v-avatar>
                            </div>
                          </template>
                          <span>{{ v.displayname || "No Name" }}</span>
                        </v-tooltip>
                      </template>
                    </div>
                  </v-list-item-content>
                </v-list-item>

                <v-divider></v-divider>
              </template>

              <v-list-item link @click="logout()">
                <v-list-item-icon class="mr-4"><v-icon dense>mdi-logout-variant</v-icon></v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>Logout</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item @click="EditDetails=true">
                <v-list-item-icon class="mr-4"><v-icon dense>mdi-pencil</v-icon></v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>Profile</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>

      </v-app-bar>
      <div class="d-flex container-scroll">
        <v-navigation-drawer class="nav-drawer height-screen" permanent touchless color="white" style=""
          v-if="clientload" :width="navminivarient && $route.path.includes('financialclose') ? 25 : navminivarient? 65 : 250" v-model="showNavgation">
          <!-- side bar for financial close -->
          <v-list v-if="$route.path.includes('financialclose')"  class="overflow-scroll d-flex flex-column , align-start justify-space-between all-width" style="overflow-y: scroll; height:95vh">
           
            <template>
              <v-list v-if="!navminivarient" class="pl-1 all-width">
                <template v-for="item in $store.state.menu">
                  <!-- Top-level items -->
                  <v-list-item
                    v-if="!item.items"
                    :key="item.name"
                    :to="$nova.gotoLink({path: item.href, hash: item.hash}, true)"
                    link
                    @click="openMenu=false"
                    class="px-2 d-flex align-center justify-start all-width black--text"
                    style="min-height:40px !important;max-height:40px"
                    :class="`${selectedItem === item.name ? 'caccounting--text radius-small selected-caccounting' : '' }`"
                  >
                      <v-icon class="pr-2">{{ item.icon }}</v-icon>
                    
                      <v-list-item-title  @click="selectedItem= item.name; openMenu=false">{{ item.name }}</v-list-item-title>
                  </v-list-item>
            
                  <!-- Items with sub-items -->
                  <v-list-group
                    v-else
                    :key="item.name"
                    no-action
                    class="  all-width black--text"
                    :value="item.name === 'Cycles' ? openMenu:false"
                  >
                    <template v-slot:activator>
                      <v-list-item class="pa-0 px-2" style="min-height:40px; max-height:40px" :class="` ${selectedItem === item.name ? 'font-weight-bold' : '' }`" >
                        <v-list-icon class="pr-2"><v-icon>{{item?.icon}}</v-icon></v-list-icon>
                        <v-list-item-content class=" pa-0 d-flex align-center justfiy-start" style="min-height:40px;max-height:40px">
                          <v-list-item-title  @click="selectedItem= item.name">{{ item.name }}</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
            
                    <!-- Render cycles -->
                    <div v-if="item.name === 'Cycles'"  style="max-height:50vh; overflow:scroll">
                      <v-list-group
                        v-for="cycle in item.items"
                        :key="cycle.name"
                        sub-group
                        no-action
                        class="px-0 ml-2 "
                        :value="isCycleOpen(cycle._id)" 
                        
                      >
                        <template v-slot:activator>
                          <v-list-item-content class="pa-2  " style="min-height:30px !important"  :class="` ${ $route.path.includes(cycle._id)  ? 'caccounting--text lightpurple ' : '' }`">
                            <v-list-item-title class=""  @click="selectedItem= cycle.name">{{ cycle.name }}</v-list-item-title>
                          </v-list-item-content>
                        </template>
            
                        <template v-for="subItem in cycle.items">
                          <!-- Regular sub-items -->
                          <v-list-item
                            v-if="!subItem.items"
                            :key="subItem.name"
                            link
                            class=" pa-0 ml-6 "
                            :class="` ${selectedItem === subItem.name ? 'caccounting--text border-left-caccounting-light selected-caccounting' : 'border-left-grey-thin' }`"
                            style="min-height:40px; max-height:40px"
                            :to="$nova.gotoLink({path: subItem.href, hash: subItem.hash}, true)"
                          >
                            <v-list-item-content class=" ">
                              <v-list-item-title class="pl-2" @click="selectedItem= subItem.name" >{{ subItem.name }}</v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
            
                          <!-- Nested sub-items (Automation Agent, Bolt On Modules) -->
                          <v-list-group
                            v-else
                            :key="subItem.name"
                            sub-group
                            no-action
                            class="pl-2 "
                          >
                            <template v-slot:activator>
                              <v-list-item-content class="py-2"  :class="` ${selectedItem === subItem.name ? 'font-weight-bold' : '' }`" >
                                <v-list-item-title  @click="selectedItem= subItem.name">{{ subItem.name }}</v-list-item-title>
                              </v-list-item-content>
                            </template>
            
                            <template v-for="nestedItem in subItem.items">
                              <!-- Regular nested items -->
                              <v-list-item
                                v-if="!nestedItem.items"
                                :key="nestedItem.name"
                                link
                                class=" ml-7 border-left-grey-thin pa-0 "
                                :to="$nova.gotoLink({path: nestedItem.href, hash: nestedItem.hash}, true)"
                                style="min-height:40px; max-height:40px"
                                :class="` ${selectedItem === nestedItem.name ? 'border-left-caccounting-light selected-caccounting' : 'border-left-grey-thin' }`"
                                
                              >
                                <v-list-item-content class="py-2 px-2">
                                  <v-list-item-title  @click="selectedItem= nestedItem.name">{{ nestedItem.name }}</v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
            
                              <!-- Double-nested items (The Variance Analysis) -->
                              <v-list-group
                                v-else
                                :key="nestedItem.name"
                                sub-group
                                no-action
                                class="ml-3"
                              >
                                <template v-slot:activator>
                                  <v-list-item-content class="py-2" :class="` ${selectedItem === nestedItem.name ? 'font-weight-bold' : '' }`">
                                    <v-list-item-title  @click="selectedItem= nestedItem.name">{{ nestedItem.name }}</v-list-item-title>
                                  </v-list-item-content>
                                </template>
            
                                <v-list-item
                                  v-for="doubleNestedItem in nestedItem.items"
                                  :key="doubleNestedItem.name"
                                  link
                                  class=" ml-6 border-left-grey-thin pa-0 "
                                  style="min-height:40px; max-height:40px"
                                  :to="$nova.gotoLink({path: doubleNestedItem.href, hash: doubleNestedItem.hash}, true)"
                                  :class="` ${selectedItem === doubleNestedItem.name ? 'border-left-caccounting-light selected-caccounting' : 'border-left-grey-thin' }`"
                                  
                                >
                                  <v-list-item-content class="pa-2">
                                    <v-list-item-title  @click="selectedItem= doubleNestedItem.name">{{ doubleNestedItem.name }}</v-list-item-title>
                                  </v-list-item-content>
                                </v-list-item>
                              </v-list-group>
                            </template>
                          </v-list-group>
                        </template>
                      </v-list-group>
                    </div>
            
                    <!-- Render other sub-items -->
                    <template v-else>
                      <v-list-item
                        v-for="subItem in item.items"
                        :key="subItem.name"
                        :to="$nova.gotoLink({path: subItem.href, hash: subItem.hash}, true)"
                        link
                        class="pl-6 "
                        style="min-height:40px; max-height:40px"
                        :class="` ${selectedItem === subItem.name ? 'caccounting--text radius-small selected-caccounting' : '' }`"
                      >
                        <v-list-item-icon>
                          <v-icon>{{ subItem.icon }}</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content class="py-2">
                          <v-list-item-title @click="selectedItem= subItem.name; openMenu=false">{{ subItem.edit=== true ?  $store.state.LocationHeader || 'Location'  : subItem.name }}</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                  </v-list-group>
                </template>
              </v-list>
            </template>
        
            <v-list class="all-width" v-if="!navminivarient">
              <v-list-group
              no-action
              class="  all-width"  >
              <template v-slot:activator>
                <v-list-item class="pa-2" >
                  <v-list-icon class="pr-2"><v-icon>mdi-apps</v-icon></v-list-icon>
                  <v-list-item-content class=" pa-0 d-flex align-center justfiy-start" >
                    <v-list-item-title  >Subscriptions</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>

              <v-list-item
                  v-for="subItem in applist"
                  :key="subItem.name"
                  class="pl-6 d-flex "
                  @click="$nova.gotoLink({ path: subItem.link })"
                  style="min-height:40px; max-height:40px"
                >
                  <v-list-item-icon>
                    <v-icon>{{ subItem.icon }}</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content class="py-2">
                    <v-list-item-title class="text-capitalize">{{ subItem.name }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

          </v-list-group>

              <v-divider> </v-divider>
              <v-list-item
              :to="$nova.gotoLink({path: '/financialclose/support'}, true)"
              link
              class="px-2 all-width d-flex align-center pt-2 justify-start  "
              style="min-height:40px;max-height:40px"
              :class="`${selectedItem === 'support' ? 'caccounting--text radius-small selected-caccounting' : '' }`"
              
            >
             
                <v-icon class="pr-3">mdi-headphones</v-icon>
                <span  @click="selectedItem= 'support'">Help & Support </span>
             
            </v-list-item>
            </v-list>




            </v-list>
          <!-- side bar for other than financial close -->
          <div v-else class="d-flex flex-column pb-0 pt-2 pr-2" style="overflow: hidden;">
            <div v-if="(($store.state.menu || [])[0] || {}).product && !navminivarient" :class="`pa-1 mb-0 ${(($store.state.menu || [])[0] || {}).bgcolor} subtitle-2 text-center white--text mr-2 mt-2 radius-right`">{{(($store.state.menu || [])[0] || {}).title}}</div>
            <div class="scroller">
              <div class="menu-sidebar">
                <v-list class="mb-6">
                  <v-list-item-group active-class="primary--text">
                    <v-slide-x-transition class="py-0" group>
                      <template v-for="(v,k) in $store.state.menu">
                        <div v-if="v.header && !navminivarient" :key="k" class="border-left-primary caption font-weight-bold mt-2 py-1 pl-2">
                          <div>{{v.title}}</div>
                        </div>
                        <div v-else-if="v.subheader && !navminivarient" :key="k" class="caption  font-weight-bold py-1 pl-4 mt-1">
                          <div>{{v.title}}</div>
                        </div>
                        <lb-divider v-if="v.header && navminivarient" :key="k" class="my-2"></lb-divider>
                        <lb-divider v-if="v.subheader && navminivarient" :key="k" class="my-2"></lb-divider>
                        <div v-else-if="v.divider" :key="k" class="my-2"><lb-divider></lb-divider></div>
                        <div v-else-if="v.button" :key="k" class="pb-4 pl-2">
                          <v-tooltip v-if="navminivarient" right content-class="tooltip-right">
                            <template v-slot:activator="{ on, attrs }" >
                              <v-btn v-bind="attrs" v-on="on" color="primary" fab small @click="$nova.gotoLink({path: v.href})">
                                <v-icon>mdi-plus</v-icon>
                              </v-btn>
                            </template>
                            <span>{{v.title}}</span>
                          </v-tooltip>
                          <v-btn v-else color="primary" small block @click="$nova.gotoLink({path: v.href})">
                            <v-icon left>mdi-plus</v-icon>
                            <span>{{v.title}}</span>
                          </v-btn>
                        </div>
                        
                        <v-list-item dense v-else-if="!v.product && v.href" :key="k" :class="`${(navminivarient)?'text-center px-3':'sb-link-slide'} ml-${2+((navminivarient?0:v.extrapad) || 0)} sb-link py-0`" :to="$nova.gotoLink({path: v.href, hash: v.hash}, true)" transition="scroll-x-transition">
                          <div class="py-0">
                            <div class="py-0 ">
                              <v-tooltip v-if="navminivarient" right content-class="tooltip-right">
                                <template v-slot:activator="{ on, attrs }" >
                                  <div v-bind="attrs" v-on="on" class="black--text d-flex align-center justify-center">
                                    <v-icon class="grey--text text--darken-3">{{v.icon}}</v-icon>
                                  </div>
                                </template>
                                <span>{{v.title}}</span>
                              </v-tooltip>
                              <div v-else class="d-flex align-center  black--text">
                                <v-icon class="nav-icon grey--text  text--darken-3 pa-1 mr-2">{{v.icon}}</v-icon>
                                {{v.title}}
                              </div>
                            </div>
                          </div>
                        </v-list-item>
                      </template>
                    </v-slide-x-transition>
                  </v-list-item-group>
                 </v-list>
              </div>
            </div>
          </div>

        </v-navigation-drawer>
        <div class="snackbar-container d-flex flex-column">
          <v-snackbar bottom dense left v-for="(sanckbar, index) in $store.state.snackMultValue.filter((s) => s.showing)" 
            :key="index + Math.random()" 
            v-model="sanckbar.showing"
            class="csnackbars pa-0" 
            min-height="0"
            min-width="0"
            elevation="0" 
            :timeout="sanckbar.time">
              <div class="shadow-on">
                <v-alert border="left" dark :type="sanckbar.type" text class="ma-0 white px-3"  dense>
                  <div class="d-flex">
                    <div class="d-flex flex-column justify-center">
                      <span class="body-2">{{sanckbar.text}}</span>
                    </div>
                    <v-btn icon small class="ml-2" :color="sanckbar.type">
                      <v-icon @click="sanckbar.showing=false">mdi-close-circle</v-icon>
                    </v-btn>
                  </div>
                </v-alert>
              </div>
          </v-snackbar>
        </div>
        <v-main style="width: 70%; background-color: white" v-resize="onResize">
          <v-btn icon small class="v-navigation-btn radius-full" @click="navminivarient = !navminivarient"><v-icon>{{ navminivarient ? 'mdi-chevron-double-right' : 'mdi-chevron-double-left' }}</v-icon></v-btn>
          <div class="all-height" style=" background-color: white">
            <div v-if="loading > 0" class="d-flex all-height align-center justify-center">
              <loader />
            </div>
            <div v-else class="main-content-holder">
              <div class="scroller" id="main-route-container">
                <div class="d-flex flex-column all-hieght">
                  <div class="flex-grow-1  all-hieght">
                    <v-fade-transition>
                      <router-view  :key="$route.fullPath"  class="position-absolute all-width all-height overflow-scroll"></router-view>
                    </v-fade-transition>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </v-main>
      </div>

      <lb-dialog v-model="EditDetails" heading="My Profile" width="600" persistent >
        <template v-slot:body>
        
          <div class="d-flex align-center mr-2 mb-3 justify-center">
            <div class="profile-wrapper">
              <v-avatar
                size="75"
                class="profile-avatar border-on"
              >
                <v-img v-if="profileImage" :src="profileImage" />
                <v-icon v-else class="placeholder-icon">mdi-account</v-icon>
              </v-avatar>
              <v-btn
                icon
                small
                class="upload-icon"
                @click="triggerFileInput"
              >
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </div>
            <input
              ref="fileInput"
              type="file"
              accept="image/*"
              @change="onImageUpload"
              class="d-none"
            />
          </div>

          <v-row>
            <v-col cols="12" >
              <v-text-field  v-model="userName" label="Name" prepend-icon="mdi-account" required></v-text-field>
            </v-col>
            <v-col cols="12" >
              <v-text-field  v-model="userEmail" readonly label="Email" prepend-icon="mdi-account" required></v-text-field>
            </v-col>
            <v-col cols="2" sm="12" class="py-0 d-flex align-center">
              <v-icon class="pr-3">mdi-phone</v-icon>
              <vue-country-code class="" :defaultCountry="CountryCode" style="height: fit-content; border:none" @onSelect="getCoutryCode"></vue-country-code>
              <v-text-field v-model="userContact" label="Contact" type="text" required @keydown="restrictInput"></v-text-field>
            </v-col>

            <v-col>                
              <div style="height:10vh"></div>
            </v-col>
          </v-row>
          <v-col cols="1" sm="12" class="d-flex align-center justify-center">
            <v-spacer></v-spacer>
            <v-btn color="primary"  class=" white--text" 
              type="submit" @click="updateProfile()">Update</v-btn>
            <v-spacer></v-spacer>
          </v-col>
        </template>
      </lb-dialog>

      <lb-drawer v-model="editProfileDrawer" heading="Profile Setting" width="800">
        <template v-slot:body>
          <div class="all-width">
            <div class="d-flex align-center justify-space-between all-width">
              <div class="d-flex align-center justify-start all-width">

                  <div class="d-flex align-center mr-2 mb-3 justify-center">
                    <div class="profile-wrapper">
                      <v-avatar
                        size="75"
                        class="profile-avatar"
                      >
                        <v-img v-if="profileImage" :src="profileImage" />
                        <v-icon v-else class="placeholder-icon">mdi-account</v-icon>
                      </v-avatar>
                      <v-btn
                        icon
                        small
                        class="upload-icon"
                        @click="triggerFileInput"
                      >
                        <v-icon>mdi-pencil</v-icon>
                      </v-btn>
                    </div>
                    <input
                      ref="fileInput"
                      type="file"
                      accept="image/*"
                      @change="onImageUpload"
                      class="d-none"
                    />
                  </div>


                <div class="d-flex flex-column align-start px-6 justify-start">
                  <p class="ma-0 pa-0 text-h6 black--text font-weight-bold">{{$store.state.user.name}}</p>
                  <p class="ma-0 pa-0 grey--text ">{{$store.state.user.designation}}</p>
                </div>
              </div>
              <v-btn color="white" @click="editProfileSetting=true; editProfileDrawer=false"><v-icon>mdi-pencil-outline</v-icon>Edit name</v-btn>
            </div>
            <v-divider></v-divider>
            <div class="mt-6">

              <v-row class="align-center">
                <v-col cols="3">
                  <p class="pa-0 ma-0">Status</p>
                </v-col>
                <v-col cols="5" >
                  <v-menu :close-on-content-click="false" offset-y class="radius-small pa-0"
                      transition="slide-y-transition" bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-list-item class="profile pa-0 ma-0 " v-bind="attrs" v-on="on">
                          <div class="border-on d-flex align-center justify-space-between  pa-1 px-2 radius-medium ">
                            <v-icon :color="userStatus.color">{{ userStatus.icon || 'mdi-history' }}</v-icon>
                            <p class="pa-0 ma-0 px-2" :class="`${userStatus.color}--text`">{{ userStatus.name || 'Status' }}</p>
                            <v-icon :color="userStatus.color">mdi-chevron-down</v-icon>
                          </div>
                        </v-list-item>
                      </template>
  
                      <v-list v-if="!addNewStatus" class="pa-0 ma-0 d-flex flex-column align-start justify-start all-width"
                          style="max-height:100vh; overflow:hidden; width:30vh">
                        <v-list-title class="grayBackground pa-2 all-width">Status</v-list-title>
  
                        <v-list  class="d-flex all-width flex-column align-start px-1" style="max-height:45vh; overflow:scroll">
                          <div class="d-flex align-center py-2 cursor-pointer  radius-small all-width"
                          :class="userStatus.name === item.name ? 'selected-item' : ''"
                          v-for="(item,index) in userStatuslist" :key="index" @click="updateStatus(item.icon,item.name,item.color)">
                          <v-icon class="px-2" :color="userStatus.name === item.name ? 'white' : 'default'">
                            {{ item.icon }}
                          </v-icon>
                            {{item.name}}
                          </div>
                        </v-list>
                        <v-divider></v-divider>
                        <v-list class="d-flex align-center px-1 all-width justify-space-between">
                          <v-spacer></v-spacer>
                          <v-btn color="primary"  @click="addNewStatus=true; newstatusname=''" class="white--text px-1 text-caption">Add new status</v-btn>
                        </v-list>
  
                      </v-list>
  
                      <v-list v-else class="pa-0 ma-0  d-flex flex-column align-start justify-start all-width">
                        <v-list-title class="d-flex align-center grayBackground justify-start all-width">
                          <v-btn icon small @click="addNewStatus=false">
                            <v-icon>mdi-keyboard-backspace</v-icon>
                          </v-btn>
                          <p class="pa-0 ma-0">Create new status</p>
                        </v-list-title>
                        <v-divider></v-divider>
                        <v-list-item class="my-2">
                          <p class="pa-1 ma-0  border-on radius-small mr-2">
                            <v-icon size="30" style="font-size:2.2rem !important">mdi-emoticon-happy-outline</v-icon>
                          </p>
                          <lb-string v-model="newstatusname" label="" :hidedetails="true" placeholder="Enter new status"></lb-string>
                        </v-list-item>
                        <v-list-item class="d-flex align-center all-width justify-space-between">
                          <v-spacer></v-spacer>
                          <v-btn color="primary"  @click.stop="addNewStatustolist" class="white--text px-1 text-caption">Add status</v-btn>
                        </v-list-item>
                      </v-list>
                  </v-menu>
                </v-col>
              </v-row>


              <v-row>

                <v-col cols="3">
                <p class="pa-0 ma-0">Email ID</p>
                </v-col>
                <v-col cols="5">
                  <p class="pa-0 ma-0">{{$store.state.user.email}}</p>

                </v-col>
              </v-row>
                
              <v-row>

                <v-col cols="3">
                <p class="pa-0 ma-0">Mobile number</p>
                </v-col>
                <v-col cols="5">
                  <p class="pa-0 ma-0">{{$store.state.user.contactno}}</p>
                </v-col>
                <v-col cols="4" class="pa-1 d-flex justify-end align-center" >
                  <v-btn color="white" class="text-caption" @click="editProfileSetting=true; editProfileDrawer=false"><v-icon>mdi-pencil-outline</v-icon>Edit number</v-btn>
                </v-col>
              </v-row>

              </div>
            </div>
          </template>
          <template v-slot:actions>
            <div class="d-flex align-center justify-end all-width">
              <v-btn class="border-dark radius-small mx-2">Request to change email</v-btn>
              <v-btn color="primary" @click="logout" link>Sign out</v-btn>
            </div>
          </template>
      </lb-drawer>

      <lb-drawer v-model="editProfileSetting" heading="Profile Setting" width="800">
        <template v-slot:body>
          <div class="all-width">
            <div class="d-flex align-center justify-space-between all-width">
              <div class="d-flex align-center justify-start all-width">

                  <div class="d-flex align-center mr-2 mb-3 justify-center">
                    <div class="profile-wrapper">
                      <v-avatar
                        size="75"
                        class="profile-avatar"
                      >
                        <v-img v-if="profileImage" :src="profileImage" />
                        <v-icon v-else class="placeholder-icon">mdi-account</v-icon>
                      </v-avatar>

                    </div>
                  </div>
                <div class="d-flex flex-column align-start px-4 justify-start">
                  <lb-string label="" hidedetails :clearable="false" placeholder="Name" v-model="userName"/>
                  <lb-string label="" class="my-2" placeholder="Designation" hidedetails :clearable="false" v-model="userDesgination"/>
                </div>
              </div>
              <v-btn color="caccounting" class="white--text" @click="updateProfile()">Done</v-btn>
            </div>
            <v-divider></v-divider>
            <div class="mt-6">

              <v-row class="align-center">
                <v-col cols="3">
                  <p class="pa-0 ma-0">Status</p>
                </v-col>
                <v-col cols="5" >
                  <v-menu :close-on-content-click="false" offset-y class="radius-small pa-0"
                      transition="slide-y-transition" bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-list-item class="pa-0 ma-0 " v-bind="attrs" v-on="on">
                          <div class="border-on d-flex align-center justify-space-between  pa-1 px-2 radius-medium ">
                            <v-icon :color="userStatus.color">{{ userStatus.icon || 'mdi-history' }}</v-icon>
                            <p class="pa-0 ma-0 px-2" :class="`${userStatus.color}--text`">{{ userStatus.name || 'Status' }}</p>
                            <v-icon :color="userStatus.color">mdi-chevron-down</v-icon>
                          </div>
                        </v-list-item>
                      </template>
  
                      <v-list v-if="!addNewStatus" class="profile pa-0 ma-0 d-flex flex-column align-start justify-start all-width"
                          style="max-height:50vh; overflow:hidden; width:30vh">
                        <v-list-title class="grayBackground pa-2 all-width">Status</v-list-title>
  
                        <v-list-item  class="d-flex all-width flex-column align-start px-1" style="max-height:45vh; overflow:scroll">
                          <div
                            class="d-flex align-center justify-start py-2 cursor-pointer  radius-small all-width"
                            v-for="(item, index) in userStatuslist"
                            :key="index"
                            @click="updateStatus(item.icon, item.name,item.color)"
                            :class="userStatus.name === item.name ? 'selected-item' : ''"
                          >
                          <v-icon class="px-2" :color="userStatus.name === item.name ? 'white' : 'default'">
                            {{ item.icon }}
                          </v-icon>
                              {{ item.name }}
                          </div>
                        </v-list-item>
                        <v-divider></v-divider>
                        <v-list-item class="d-flex align-center all-width justify-space-between">
                          <v-spacer></v-spacer>
                          <v-btn color="primary"  @click="addNewStatus=true; newstatusname=''" class="white--text px-1 text-caption">Add new status</v-btn>
                        </v-list-item>
  
                      </v-list>
  
                      <v-list v-else class="pa-0 ma-0  d-flex flex-column align-start justify-start all-width">
                        <v-list-title class="d-flex align-center grayBackground justify-start all-width">
                          <v-btn icon small @click="addNewStatus=false">
                            <v-icon>mdi-keyboard-backspace</v-icon>
                          </v-btn>
                          <p class="pa-0 ma-0">Create new status</p>
                        </v-list-title>
                        <v-divider></v-divider>
                        <v-list-item class="my-2">
                          <p class="pa-1 ma-0  border-on radius-small mr-2">
                            <v-icon size="30" style="font-size:2.2rem !important">mdi-emoticon-happy-outline</v-icon>
                          </p>
                          <lb-string v-model="newstatusname" label="" :hidedetails="true" placeholder="Enter new status"></lb-string>
                        </v-list-item>
                        <v-list-item class="d-flex align-center all-width justify-space-between">
                          <v-spacer></v-spacer>
                          <v-btn color="primary"  @click.stop="addNewStatustolist" class="white--text px-1 text-caption">Add status</v-btn>
                        </v-list-item>
                      </v-list>
                  </v-menu>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="3">
                <p class="pa-0 ma-0">Email ID</p>
                </v-col>
                <v-col cols="5">
                  <p class="pa-0 ma-0">{{$store.state.user.email}}</p>

                </v-col>
              </v-row>
              <v-row class="align-center justify-start d-flex">
                <v-col cols="3">
                <p class="pa-0 ma-0">Mobile number</p>
                </v-col>
                <v-col cols="5">
                  <lb-string label=""  :hidedetails=true :clearable="false" v-model="userContact"></lb-string>
                </v-col>
                <v-col cols="4" class="pa-1 d-flex justify-end align-center" >
                  <v-btn color="caccounting" class="white--text" @click="updateProfile()">Done</v-btn>
                </v-col>
              </v-row>
            </div>
          </div>
        </template>
        <template v-slot:actions>
          <div class="d-flex align-center justify-end all-width">
            <v-btn class="border-dark radius-small mx-2" >Request to change email</v-btn>
            <v-btn color="primary" @click="logout" link>Sign out</v-btn>
          </div>
        </template>
      </lb-drawer>
    </v-layout>
  </v-app>
</template>

<script>
import loader from './common/Loader';

export default {
  name: "app",
  data: () => ({
    group: null,
    login: false,
    showNavgation: true,
    navminivarient: true,
    showHeaderAction: true,
    containerHeight: 0,
    clientload: false,
    downloaddialog: false,
    activemenu: 0,
    reloadkey: 0,
    display: "load",
    loading: 0,
    routeid: "",
    applist: [
    ],
    appname: "",
    selectedclient: "",
    pathName: "",
    isPlanExpaired: false,
    newMenuList: [],
    admins: [
      ['Management', 'mdi-account-multiple-outline'],
      ['Settings', 'mdi-cog-outline'],
    ],
    editProfileDrawer:false,
    userStatuslist:[
      {
        icon:"mdi-check-circle",
        name:"Available",
        color:'success'
      },
      {
        icon:"mdi-calendar-blank",
        name:" In a meeting",
        color:'info'
      },
      {
        icon:"mdi-minus-circle-outline",
        name:"Do not disturb",
        color:'error'
      },
      {
        icon:"mdi-close-circle-outline",
        name:"Appear Away",
        color:'primary'
      },
    ],
    userStatus:{},
    newstatusname:"",
    addNewStatus:false,
    profileImage: null,
    editProfileSetting:false,
    userName:"",
    userContact:"",
    userDesgination:"",
    EditDetails:false,
    userEmail:'',
    CountryCode:'',
    selectedItem: null,
    openMenu:false
  }),
  components: {
    loader
  },
  created() {
    // this.$store.dispatch('fetchCycleList');
    this.navminivarient = localStorage.getItem("navminivarient") === "true" ? true : false;
    this.profileImage=this.$store.state.user.profile || null
    this.userName=this.$store.state.user.name||""
    this.userContact=this.$store.state.user.contactno||""
    this.userDesgination=this.$store.state.user.designation||""
    this.userEmail=this.$store.state.user.email
    const existingStatus = this.userStatuslist.find(
      status => status.name === this.$store.state.user.activestatus
    );
    if (existingStatus) {
      this.userStatus = { icon: existingStatus.icon, name: existingStatus.name, color:existingStatus.color };
    } else {
      let newstatus = {
        icon: "mdi-emoticon-happy-outline",
        name: this.$store.state.user.activestatus,
        color:'caccounting'
      };
      this.userStatuslist.push(newstatus);
      this.userStatus = { icon: newstatus.icon, name: newstatus.name , color: newstatus.color };
    }

    if (this.navminivarient !== this.$store.state.mininavbar) this.$store.commit('toogleNavBar');
    // this.setMenu(this.$route.path);
    this.getLocationHeader();
    this.getDetails();
  },
  mounted() {
    this.selectedclient = this.$route.query.cid || "";
    this.dragFunctions();
    this.onResize();
  },
  methods: {
    isCycleOpen(cycleId) {
      if(this.$route.path.includes(cycleId)){
        this.openMenu=true;
      }
      this.openMenu=true;
      return this.$route.path.includes(cycleId);
    },
    onImageUpload(event) {
      const file = event.target.files[0];
      this.updateProfile(file)
      if (file) {
        this.profileImage = URL.createObjectURL(file);
      }
    },
    restrictInput(event) {
      const key = event.key;
      if (!/^[0-9]$/.test(key) && !['Backspace', 'ArrowLeft', 'ArrowRight', 'Delete', 'Tab'].includes(key)) {
        event.preventDefault();
      }
    },
    triggerFileInput() {
      this.$refs.fileInput.click();
    },
    getCoutryCode({iso2}) {
      this.userDetails.countrycode = iso2
    },  
    updateStatus(icon, name,color) {
      this.userStatus = { icon, name,color };
      this.updateProfile() 
    },
    addNewStatustolist() {
      let newstatus = {
        icon: "mdi-emoticon-happy-outline",
        name: this.newstatusname,
        color:'caccounting'
      };
      if(!this.userStatuslist.includes(newstatus))
      {
        this.userStatuslist.push(newstatus);
      }
      this.updateStatus(newstatus.icon,newstatus.name);
      this.addNewStatus=false
    },
    changeNavVarient(flag) {
      this.$store.commit('toogleNavBar', flag);
    },
    getDetails() {
      if (!this.$store.state.noauth) {
        this.$store.commit("addLoading");
        this.loading++;
        let pcodes = [];
        let rdata = {};
        this.axios.post("/v2/profile/getdetails").then(dt => {

          if (dt.data.status === "success") {
            rdata = (dt.data.data || [])[0] || {};
            let clientdata = rdata.clients || [];
            this.$store.commit("setClientList", clientdata);
            this.$store.commit("refreshClient", false);
            if (clientdata.length > 0) {
              let clientinurl = "";
              if (this.$route.query.cid) {
                for (const i of clientdata) {
                  if (i._id === this.$route.query.cid) clientinurl = i._id;
                }
              }
              if(localStorage.getItem("Entity") !=this.selectedclient){
                localStorage.setItem("userObj",'')
              }
              if (clientinurl) {
                if (clientinurl !== this.selectedclient) this.selectedclient = clientinurl;
              }
              else this.selectedclient = clientdata[0]._id;
              localStorage.setItem("Entity",this.selectedclient)
            }
            let subsdata = rdata.subscription || {};
            for (const productSub in subsdata) {
              if (productSub === "financialclose") {
                let data = subsdata.financialclose;
                if (data.plan_detail.name === "Free") {
                  this.isPlanExpaired = data.plan_detail.expiry || false;
                  if (this.isPlanExpaired) {
                    this.$store.state.m_financialclose.menu = [];
                  }
                }
              }
              else if (productSub === "varianceanalysis") {
                let data = subsdata.varianceanalysis;
                if (data.plan_detail.name === "Free") {
                  this.isPlanExpaired = data.plan_detail.expiry || false;
                  if (this.isPlanExpaired) {
                    this.$store.state.m_varianceanalysis.menu = [];
                  }
                }
              }
            }
            if (Object.keys(subsdata).length <= 2) {
              let redirectlink = "";
              let redirectflag = true;
              for (const k in subsdata) {
                if (Object.hasOwnProperty.call(subsdata, k)) {
                  if (k !== 'admin') {
                    const el = subsdata[k];
                    if (redirectlink === "") redirectlink = el?.product_detail?.homepage || "";
                    else redirectflag = false
                  }
                }
              }
              if (redirectflag) this.$nova.gotoLink({ path: redirectlink });
            }
            this.$store.commit("setSubscription", subsdata);

            for (const k in subsdata) {
              if (Object.hasOwnProperty.call(subsdata, k)) {
                const el = subsdata[k];
                pcodes.push(el.product_detail.code);
              }
            }
            return this.axios.post("/v2/auth/getpermission", {});
          } else {
            this.$store.commit("sbError", "Error fetching client list");
          }
        }).then(dt => {
          if (dt.data.status === "success") {
            if (dt.data.data.length > 0) {
              this.$store.commit("setPermission", dt.data.data[0]);
              let perm = dt.data.data[0]
              this.applist = [];
              if (pcodes.indexOf('pbc') > -1 && perm.pbc_project) this.applist.push({ name: "pbc", displayname: "Information Workflow", color: "crisk", avatartext: "I", logo: "", icon: "mdi mdi-axis-arrow-info", link: "/pbc/project" });
              if (pcodes.indexOf('tds_ar') > -1 || pcodes.indexOf('tds_ap') > -1 || (pcodes.indexOf('tds_monthly') > -1 && perm.tds_monthly)) this.applist.push({ name: "tds", displayname: "Witholding Tax", color: "ccompliance", avatartext: "T", logo: "", icon: "mdi mdi-check-decagram-outline", link: "/tds" });
              if (pcodes.indexOf('earnout') > -1) this.applist.push({ name: "earnout", displayname: "Earnouts", color: "ctransaction", avatartext: "E", logo: "", icon: "mdi mdi-bank-transfer", link: "/earnout/project" });
              if (pcodes.indexOf('financialclose') > -1) this.applist.push({ name: "financialclose", displayname: "Financial Close", color: "caccounting", avatartext: "F", logo: "", icon: "mdi mdi-book-open", link: "/financialclose/home" });
              if (pcodes.indexOf('ia') > -1) this.applist.push({ name: "ia", displayname: "Internal Audit", color: "crisk", avatartext: "IA", logo: "", icon: "mdi mdi-order-bool-ascending-variant", link: "/ia/project" });
              if (pcodes.indexOf('bot') > -1 && perm.bot_project) this.applist.push({ name: "bot", displayname: "Bots", color: "crisk", avatartext: "B", logo: "", icon: "mdi mdi-robot-excited-outline", link: "/bot/project" });
              if (pcodes.indexOf('flux') > -1) this.applist.push({ name: "flux", displayname: "Flux Management", color: "caccounting", avatartext: "F", logo: "", icon: "mdi-projector-screen-variant-outline", link: "/flux/project" });
              if (pcodes.indexOf('helpdesk') > -1) this.applist.push({ name: "helpdesk", displayname: "Help Desk", color: "primary", avatartext: "H", logo: "", icon: "mdi mdi-help-box", link: "/helpdesk/queries" });
              if (pcodes.indexOf('varianceanalysis') > -1) this.applist.push({ name: "varianceanalysis", displayname: "Commentary", color: "caccounting", avatartext: "C", logo: "", icon: "mdi-google-analytics", link: "/varianceanalysis/actualanalysis" });
              if (pcodes.indexOf('admin') > -1) this.applist.push({ name: "admin", displayname: "Admin", color: "primary", avatartext: "A", logo: "", icon: "mdi mdi-shield-crown-outline", link: "/admin/dashboard" });
            }
          } else throw Error("Error fetching permission details");
        }).then(()=>{
          console.log("six month");
          
          // this.axios.post("/v2/financialclose/project/getmonth").then(dt => {
          //   let cycle = localStorage.getItem("userObj");
          //   if(cycle){
          //   cycle= JSON.parse(cycle)
          //   const existingItem =dt.data.data.find(olditem => olditem.name === cycle.description);
          //   if(!existingItem){
          //     dt.data.data.push(cycle)
          //   }
          //   }
          //   this.$store.commit("setNewList", dt.data.data)
          // })
        }).catch(err => {
          this.$store.commit("sbError", err.message || "Unknown error!");
          console.log(err);
        }).finally(() => {
          this.onResize();
          this.clientload = true;
          this.reloadkey += 1;
          this.loading--;
          this.checkSubscriptionPermission(this.$route, this.$route);
          this.$store.commit("removeLoading");
        });
      } else {
        this.reloadkey += 1;
        this.clientload = true;
      }
      if (this.$route.path === "/"){
      
      if(this.applist.find((item)=> item.name ==='financialclose'))
      {
        this.$nova.gotoLink({ path: '/financialclose/home' })
      }
      else if(this.applist.find((item)=> item.name ==='varianceanalysis'))
      {
        this.$nova.gotoLink({ path: '/varianceanalysis/actualanalysis' })
      }
      else{        
        this.$nova.gotoLink({ path: this.applist[0].link })
      }
      }
    },
    logout() {
      this.axios.post('/v2/auth/logout', {
      }).then(x => {
        if (x.data.status == "success") {
          this.$store.commit("changeLogin", false);
          this.$store.commit("setUser", null);
          this.$router.push('/');
        }
      }).catch(e => {
        this.$store.commit("sbError", e.message || "Logout error!");
        console.log(e);

      })
    },
    setMenu(path) {
      this.appname = "m_" + (path || "").split("/")[1] || "";
      // console.log( this.appname,"FFF");
      let menuitems = [];
      let storeobj = this.$store.state[this.appname] || {};
      if (storeobj.menu) {
        if (this.$store.state.newmenu.length > 0) menuitems = [...this.$store.state.newmenu, ...this.$store.state.custommenu];
        else menuitems = [...this.$store.state[this.appname]?.menu, ...this.$store.state.custommenu];
      }
      else menuitems = [];
      let newmenu = [];
      let dummymenu = [];
      let headerstart = false;
      let menupostsubs = [];
      let addcust = this.$route.meta?.submenu || [];
      let params = this.$route.params;
      // console.log(this.$route);
      for (const i of menuitems) {
        if (i.submenu && addcust.indexOf(i.variable) > -1) {
          if (storeobj[i.variable]) {
            let nm = [];
            // console.log("in1111")
            for (const j of storeobj[i.variable]) {
              if (j.href) {
                let nj = { ...j };
                // console.log("in1122", nj.href);
                let replacetexts = nj.href.match(/\${2}\w+[^_]\${2}/g) || [];
                for (const k of replacetexts) {
                  let ttext = k.replaceAll("$", "");
                  // console.log("in2222", k, params[ttext]);
                  if (params[ttext]) nj.href = nj.href.replaceAll(k, params[ttext]);
                  // console.log("post replace", nj.href);
                }
                nm.push(nj)
              } else nm.push(j);
            }
            menupostsubs = [...menupostsubs, ...nm]
          }
        } else menupostsubs.push(i);
      }
      for (const i of menupostsubs) {
        if (i.product) newmenu.push(i);
        else if (i.header) {
          if (headerstart) {
            if (dummymenu.length > 1) newmenu = [...newmenu, ...dummymenu];
            dummymenu = [];
          }
          dummymenu.push(i);
          headerstart = true;
        } else {
          if (this.$nova.hasRight(i.right, i.action)) {
            if (headerstart) dummymenu.push(i);
            else newmenu.push(i);
          }
        }
      }
      if (dummymenu.length > 1) newmenu = [...newmenu, ...dummymenu];
      this.$store.commit("setMenu", newmenu);
    },
    setCustomMenu() {
    },
    onResize() {
      setTimeout(() => {
        if (!this.$vuetify.breakpoint.lgAndUp) {
          this.$store.commit('toogleNavBar', true);
          this.navminivarient = true;
        }
      }, 500);
      if (this.$refs.toolbar && document.getElementById("main")) {
        this.$store.commit("setHeight", (((document.getElementById("main").offsetHeight) - (this.$refs.toolbar.$el.offsetHeight || 0) - 2) || '100vh'));
      }
    },
    getExtImage: function (n) {
      let na = n.split(".");
      let ext = na[na.length - 1];
      if (ext === "pptx") return "/img/tpa_icons/ppt.jpg";
      else if (ext === "xlsx") return "/img/tpa_icons/excel.jpg";
      else if (ext === "zip") return "/img/tpa_icons/zip.jpg";
      else return "";
    },
    dragFunctions() {
      document.addEventListener('dragstart', event => {
        event.target.style.opacity = 0.5;
      });
      document.addEventListener('dragend', event => {
        event.target.style.opacity = 1;
      });
    },
    checkSubscriptionPermission(to, from) {
      // console.log("called check");
      if (from.query.cid === to.query.cid) {
        // console.log("called check", this.$nova.hasRight(to.meta.right, to.meta.action));
        let hassub = false;
        let requiredsubs = to.meta.subscription || [];
        let avaliablesubs = this.$store.state.subscriptions;
        for (const i of (requiredsubs)) {
          if (avaliablesubs[i || "xxxxx"] || avaliablesubs[(i || "").toUpperCase() || "xxxxx"]) hassub = true;
        }
        let haspermission = true;
        if (to.meta.right) haspermission = this.$nova.hasRight(to.meta.right, to.meta.action);
        // console.log("called check", hassub, haspermission, to.meta.allow, process.env.VUE_APP_IGNORE_SUBSCRIPTIONS);
        if (!((hassub && haspermission) || to.meta.allow || process.env.VUE_APP_IGNORE_SUBSCRIPTIONS === '1')) {
          let routeData = {
            path: to.path,
            query: { ...to.query },
            hash: to.hash,
          };
          let oldpath = JSON.stringify(routeData);

          if(this.applist.find((item)=> item.name ==='financialclose'))
            {
              routeData.path= '/financialclose/homeview' 
            }
            else if(this.applist.find((item)=> item.name ==='varianceanalysis'))
            {
              routeData.path= '/varianceanalysis/actualanalysis' 
            }
            else{        
              routeData.path= this.applist[0].link 
            }
          let newpath = JSON.stringify(routeData);
          if (oldpath !== newpath) {
            // console.log(from, to);
            if (!hassub) this.$router.push(routeData);
            else this.$router.go(-1);
          }
        }
        this.setCustomMenu();
        this.setMenu(to.path);
      }
    },
    findIcon(name){
     let iconname= this.$store.state.menu.filter((item)=>{
        if(item.title === name) 
          return item.icon
      })
      return iconname[0].icon
    },
    findlist(){
      const result = {};
      let currentKey = null;
      this.$store.state.menu.forEach(obj => {
        if (obj.header) {
          currentKey = obj.title;
          result[currentKey] = [];
        } else if (currentKey) {
          result[currentKey].push(obj);
        }
      });
      this.newMenuList = result;
    },

    async updateProfile(file=null) {      
      const now = new Date();
      const epochTime = now.getTime();
      if (!this.$store.state.noauth) {
        let profilePath ='';
        let details = {
          username:this.userName || this.$store.state.user.name ||"",
          contactno: this.userContact  || this.$store.state.user.contact||"",
          countrycode: this.$store.state.user.countrycode || "",
          email: this.$store.state.user.email||"",
          designation:this.userDesgination|| this.$store.state.user.designation || "",
          activestatus: this.userStatus.name|| this.$store.state.user.activestatus || "",
        };
        if(file)
        {
          profilePath =await this.$nova.uploadFile(this.axios, file );
          details ={...details, 
            profile_url: profilePath.data.data[0].path,
            upload_id: profilePath.data.data[0]._id,
            profile_url_expiration :epochTime,
        }
      }
      
      this.axios.post("/v2/userprofile/add", {data:[details]}).then(dt => {
        if(dt.data.status=='success')
        {
          this.$store.commit("sbSuccess", "Profile Updated successfully")
          this.$store.state.user=dt.data.data[0]
          this.$store.state.user.id=dt.data.data?.[0]?._id || ''
        }
        else
        {
          this.$store.commit("sbError", dt.data.message || "Unknown error!");
        }
        this.profileImage = dt.data.data[0].profile_url
        this.editProfileDrawer=false
        this.editProfileSetting=false
      });
      }
      },
      getLocationHeader(){
      this.axios.post("v2/client/getlocation").then(dt => {
        this.$store.commit('setLocationHeader',dt.data.data[0].location);        
      })
    },
      
  },
  computed: {
  },
  watch: {
    $route(to, from) {
      this.checkSubscriptionPermission(to, from);
    },
    selectedclient: function (d) {
      this.$store.commit("setClient", d);
    },
    '$store.state.client': function (d) {
      this.selectedclient = d;
      let routeData = {
        path: this.$route.path,
        query: { ...this.$route.query },
        hash: this.$route.hash,
      };
      let oldpath = JSON.stringify(routeData);
      routeData.query.cid = d;
      let newpath = JSON.stringify(routeData);
      console.log("Qwer5t6y7u8iop",routeData.hash);
      
      if (oldpath !== newpath) {
        this.$router.push(routeData);
      }
    },
    '$store.state.refreshclient': function (d) {
      if (d) this.getDetails();
    },
    '$store.state.containerheight': function (d) {
      this.containerHeight = d + "px";
    },
    '$store.state.navigation': function (d) {
      this.showNavgation = d;
    },
    '$store.state.headeraction': function (d) {
      this.showHeaderAction = d;
    },
    '$store.state.custommenu': function () {
      this.setMenu(this.$route.path);
    },
    '$store.state.mininavbar': function () {
      this.navminivarient = this.$store.state.mininavbar;
      localStorage.setItem("navminivarient", this.navminivarient);
    },
    '$store.state.menu': function () {
      this.findlist()
    }
  },
};
</script>

<style lang="scss">
* {
  font-size: 0.90rem !important ;
}

.nav-drawer {
  background-color: transparent !important;
  border-right: 0;
}

.navigation-container {
  height: 100%;
  background-color: white;
}
.ellipsis-on {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.main-container{
  height: 100vh;
  display: flex;
  flex-direction: column;
  max-height: 100%;
}
.main-container .main-content-holder{
  flex-grow: 1;
}
.v-data-table__empty-wrapper td{
  font-size: 1.3em !important;
}
.scroller{
  height: 100%;
  overflow: auto;
}
::-webkit-scrollbar {
  width: 6px;
  position: absolute;
  right: 5px;
  height:6px
}
/* Track */
::-webkit-scrollbar-track {
  background: transparent; 
  border-radius: 100px;
}  
/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(0,0,0,0.3); 
  border-radius: 100px;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(0,0,0,0.2); 
}

.navigation-container .menu-sidebar-psb {
  flex-grow: 1;
}

.ellipsis-on {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.main-container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  max-height: 100%;
}

.main-container .main-content-holder {
  flex-grow: 1;
}

.scroller {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

::-webkit-scrollbar {
  width: 6px;
  position: absolute;
  right: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 100px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.3);
  border-radius: 100px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.2);
}

.main-container .main-content-holder .main-content-holder-psb {
  height: 100%;
}

.main-container .main-container-toolbar {
  flex-grow: 0;
  z-index: 100;
  position: sticky;
  background-color: rgba(255, 255, 255, 1) !important;
}

.main-container .main-container-toolbar .v-toolbar__content {
  padding-left: 0;
}

.snackbar-container {
  position: fixed;
  z-index: 10000;
  overflow: hidden;
  left: 5vh;
  bottom: 5vh;
}
.position-fixed{
  position: fixed !important;
}
.v-snack:not(.v-snack--absolute) {
  height: auto !important;
  position: initial !important;
}

.snackbar-container .v-snack__content {
  padding: 0 !important;
}

.snackbar-container .v-snack__wrapper {
  margin-top: 0 !important;
}

.v-icon {
  font-size: 1.1rem !important;
}

.v-btn {
  box-shadow: none !important;
}

.menu-sidebar .sb-link {
  min-height: 32px !important;
  border-radius: 12px !important;
  overflow: hidden;
  transition: padding 0.15s ease;
  padding-left: 5px;
}

.menu-sidebar .sb-link-slide:hover {
  padding-left: 7px;
  // padding-left: 10px !important;
}

.menu-sidebar {
  background-color: #fff !important;
  color: var(--v-primary-base) !important;
}

.bg-design-box {
  position: absolute;
  z-index: -1000;
}

.line-1 {
  line-height: 1 !important;
}

.line-1-2 {
  line-height: 1.2 !important;
}

.line-1-4 {
  line-height: 1.4 !important;
}

.all-height {
  height: 100%;
}

.all-width {
  width: 100%;
}

.overflow-card-top {
  position: absolute;
  margin-top: -40px;
  z-index: 100;
}

@keyframes bg-design-1-animation {
  0% {
    bottom: 0;
    left: -10vw;
    transform: rotate(20deg);
  }

  40% {
    bottom: 2vh;
    left: -10vw;
    transform: rotate(23deg);
  }

  70% {
    bottom: 5vh;
    left: -12vw;
    transform: rotate(18deg);
  }

  100% {
    bottom: 0;
    left: -10vw;
    transform: rotate(20deg);
  }
}

.bg-design-1 {
  // animation-name: bg-design-1-animation; 
  animation-duration: 15s;
  animation-timing-function: ease;
  animation-iteration-count: infinite;
  background-color: rgba(252, 99, 107, 0.2);
  height: 50vh;
  width: 20vw;
  bottom: -10vh;
  border-radius: 25px;
  left: -10vw;
  transform: rotate(20deg);
  z-index: 0;
}

@keyframes bg-design-2-animation {
  0% {
    bottom: -10vh;
    left: -15vw;
    transform: rotate(40deg);
  }

  40% {
    bottom: -12vh;
    left: -15vw;
    transform: rotate(45deg);
  }

  70% {
    bottom: -8vh;
    left: -19vw;
    transform: rotate(35deg);
  }

  100% {
    bottom: -10vh;
    left: -15vw;
    transform: rotate(40deg);
  }
}

.bg-design-2 {
  // animation-name: bg-design-2-animation;
  animation-duration: 12s;
  animation-timing-function: ease;
  animation-iteration-count: infinite;
  background-color: var(--v-primary-base);
  opacity: 0.2;
  height: 40vh;
  width: 30vw;
  bottom: -15vh;
  border-radius: 25px;
  left: -15vw;
  transform: rotate(40deg);
  z-index: 0;
}

@keyframes bg-design-3-animation {
  0% {
    top: -45vh;
  }

  40% {
    top: -40vh;
  }

  70% {
    top: -50vh;
  }

  100% {
    top: -45vh;
  }
}

.bg-design-3 {
  // animation-name: bg-design-3-animation;
  animation-duration: 22s;
  animation-timing-function: ease;
  animation-iteration-count: infinite;
  // background-color: rgba(46, 182, 125, 0.2);
  height: 100vh;
  width: 60vw;
  top: -45vh;
  border-radius: 25px;
  right: -40vw;
  transform: rotate(-40deg);
  z-index: 0;
}

/* hide the "scrim", it's pointless */
.v-overlay--active .v-overlay__scrim {
  display: none;
}

/* style the overlay container as required */
.v-overlay--active {
  backdrop-filter: blur(2px);
  background: rgb(0 0 0 / 0.4);
}

.bgcolor-primary-20 {
  background-color: rgba(25, 52, 65, 0.2);
}

.bgcolor-crisk-20 {
  background-color: rgba(46, 182, 125, 0.2);
}

.bgcolor-ccompliance-20 {
  background-color: rgba(252, 99, 107, 0.2);
}

.bgcolor-caccounting-20 {
  background-color: rgba(106, 103, 206, 0.2);
}

.bgcolor-ctransaction-20 {
  background-color: rgba(66, 133, 244, 0.2);
}

.csnackbars>div {
  background-color: transparent !important;
}

table {
  border-collapse: collapse !important;
}

.hoverable-caccounting:hover {
  background-color: var(--v-lightpurple-base) !important;
  color: var(--v-caccounting-base) !important;
 }
 .selected-caccounting {
  background-color: var(--v-lightpurple-base) !important;
  color: var(--v-caccounting-base) !important;
 }
.cursor-pointer {
  cursor: pointer;
}

// For Drag and drop
.cursor-drag {
  cursor: move;
  /* fallback if grab cursor is unsupported */
}

.cursor-drag:focus {
  background-color: #000 !important;
  color: #aaa;
}

.text-transform-none {
  text-transform: none !important;
}

.runningloader {
  position: relative;
  display: inline-block;
  height: 8px;
  width: 8px;
}

.runningloader .inner-circle,
.runningloader .outer-circle {
  background-color: rgba(230, 0, 0, 1);
  border-radius: 100%;
  height: 100%;
  width: 100%;
  position: absolute;
}

.runningloader .outer-circle {
  left: 0;
  top: 0;
  animation: runninganimation 1s infinite cubic-bezier(.1, .51, .1, .51);
}

@keyframes runninganimation {
  0% {
    transform: scale(1, 1);
    background: rgba(230, 0, 0, 1);
  }

  100% {
    transform: scale(3, 3);
    background: rgba(230, 0, 0, 0.1);
  }
}


.view-on-hover-area .view-on-hover-item {
  visibility: hidden;
}

.view-on-hover-area:hover .view-on-hover-item {
  visibility: initial;
}

.view-on-hover-area:hover .hide-on-hover-item {
  visibility: hidden;
}

.view-on-hover-area .view-on-hover-item-d {
  display: none;
}

.view-on-hover-area:hover .view-on-hover-item-d {
  display: initial;
}

.view-on-hover-area:hover .hide-on-hover-item-d {
  display: none;
}

.border-left-ccompliance {
  border-left: 4px solid var(--v-ccompliance-base) !important;
  border-left-color: var(--v-ccompliance-base) !important;
}

.border-left-crisk {
  border-left: 4px solid var(--v-crisk-base) !important;
  border-left-color: var(--v-crisk-base) !important;
}

.border-left-caccounting {
  border-left: 4px solid var(--v-caccounting-base) !important;
  border-left-color: var(--v-caccounting-base) !important;
}

.border-left-ctransaction {
  border-left: 4px solid var(--v-ctransaction-base) !important;
  border-left-color: var(--v-ctransaction-base) !important;
}

.border-left-transparent {
  border-left: 4px solid transparent !important;
  border-left-color: transparent !important;
}

.border-left-success {
  border-left: 4px solid var(--v-success-base) !important;
  border-left-color: var(--v-success-base) !important;
}

.border-left-error {
  border-left: 4px solid var(--v-error-base) !important;
  border-left-color: var(--v-error-base) !important;
}

.border-left-default {
  border-left: 4px solid var(--v-default-base) !important;
  border-left-color: var(--v-default-base) !important;
}

.border-left-warning {
  border-left: 4px solid var(--v-warning-base) !important;
  border-left-color: var(--v-warning-base) !important;
}

.border-left-primary {
  border-left: 4px solid var(--v-primary-base) !important;
  border-left-color: var(--v-primary-base) !important;
}

.border-left-info {
  border-left: 4px solid var(--v-info-base) !important;
  border-left-color: var(--v-info-base) !important;
}

.border-left-grey {
  border-left: 4px solid rgba(0, 0, 0, 0.2) !important;
  border-left-color: rgba(0, 0, 0, 0.2) !important;
}

.border-left-grey-thin {
  border-left: 1px solid rgba(0, 0, 0, 0.2) !important;
  border-left-color: rgba(0, 0, 0, 0.2) !important;
}
.border-left{
  border-left: 1px solid #E5E5E5;
}

.border-left-caccounting-light{
  border-left: 2px solid var(--v-caccounting-base) !important;
  border-left-color: var(--v-caccounting-base) !important;
}
.border-right{
  border-right: 1px solid #E5E5E5;
}

.radius-small {
  border-radius: 8px !important;
}

.radius-mini {
  border-radius: 4px;
}

.radius-full{
  border-radius: 100%;
}

.radius-medium{
  border-radius: 16px !important;
}

.all-hieght {
  height: 100%;
}

.custom-table {
  background-color: var(--v-grey-base) !important;
  color: var(--v-black-base) !important;
  text-align: left !important;
}

.v-btn,
.v-card {
  border-radius: 8px;
}
.v-card .v-card__text{
  color:black !important

}

.column-center-item {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.center-item {
  display: flex;
  align-items: center;
  justify-content: center;
}

.border-on {
  border: 1px solid #E5E5E5 !important;
}

.border-dark {
  border: 2px solid #2C2C35
}

.blur-background {
  opacity: 0.9;
  background-color: lightgray;
  filter: blur(1px);
}

.border-right-data {
  border-right: 1px solid #E5E5E5;
}

.border-bottom {
  border-bottom: 1px solid #E5E5E5 !important;
}

.line-clamp-1 {
  display: inline;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}

.v-toolbar__content {
  width: 100%;
}


.v-data-table th {
  background-color: #e7f0f5 ;
  color: rgba(0, 0, 0, .9) ;
}

.v-list-group__header{
  padding: 5px;
}
.v-list-group__items{
  max-height: fit-content;
  overflow: scroll;
}
.v-list-group{
  max-height: 100%;
}
.tablerow {
  background-color: #D5D5EE !important; 
  border:none;
}
.single-line-clamp {
  display: -webkit-box;       
  -webkit-box-orient: vertical; 
  -webkit-line-clamp: 1;       
  overflow: hidden;            
  text-overflow: ellipsis;      
}
.two-line-clamp {
  display: -webkit-box;        
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;       
  overflow: hidden;             
  text-overflow: ellipsis;    
  word-wrap: break-word;      
}
.dashed_border{
  border: 1px dashed #ddd;
}
.v-navigation-drawer__content{
  overflow: hidden ;
}
.grayBackground{
  background-color: #F7F7F7 !important;
}

.simple-table th:not(:last-child),
.simple-table td:not(:last-child) {
  border-right: 1px solid #E5E5E5;
  border-bottom: 1px solid #E5E5E5;
}

.v-pagination__item , .v-pagination__navigation{
  box-shadow: none !important;
}
.v-input--selection-controls__input{
  margin-right: 0px !important
}

.v-navigation-btn{
  position: absolute;
  z-index: 10;
  left: -0.7%;
  top:10%;
  border:1px solid #ddd;
  border-radius: 50%;
  height: 26px !important;
  width: 20px !important;
  min-width: 20px !important;
  background:white;
  padding: 0px 12px !important
}

.container-scroll{
  height: 100vh ;
}


.avatars-group__item {
  margin-left: -6px;
  transition: all 0.1s ease-out;
  border:1px solid white;
  border-radius: 50%;
}


.v-avatar {
  background-color: #bdbdbd; 
  width: 40px;
  height: 40px;
  font-size: 110%;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
}

.custom-avatar {
  width: 5px;
  height: 5px;
  font-size: 0; 
}
.v-timeline__item .v-timeline__icon, .v-timeline-item__inner-dot, .v-timeline-item__dot {
  width: 5px !important;
  height: 5px !important;
  margin-right:8px
}
.position-absolute{
  position: absolute;
}
.low-opacity{
  color:#B2B2B4
}
.v-menu__content{
  min-width: fit-content ;
}

.profile-wrapper {
  position: relative;
  display: inline-block;
}

.profile-avatar {
  background-color: #f0f0f0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.placeholder-icon {
  font-size: 48px;
  color: #ccc;
}

.upload-icon {
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: #fff;
  border: 2px solid #ddd;
  border-radius: 50%;
  padding: 4px;
  color: #000;
}

.d-none {
  display: none;
}

.selected-item {
  background-color: #6B67CE !important;
  color: white !important;
}
.selected-item v-icon {
  color: white !important;
}

.sidebareffect.v-list-item--active {
  color: #6B67CE !important;
  background-color: #F4F4FB;
  font-weight: bold;
  border-radius: 8px;
  margin:0px 3px
}

.overflow-scroll{
  overflow: scroll;
}


.width-fit{
  min-width: fit-content
}
.w-60{
  width: 54%;
}
.avatar-white-border{
  border: 1px solid white;
  border-radius: 50%;
  z-index: 1;
}
.caccounting-background{
  background-color: #F4F4FB
}
.single-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 20vh;

}

.font-weight-semibold{
  font-weight: 550 !important
}
.gray-background{
  background-color: #F7F7F7 !important
}
.description-header{
  min-width:45vh
}
.line-clamp-2{
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2; 
  -webkit-box-orient: vertical;
}
.font-small{
  font-size: 0.8rem !important;
}
.v-pagination__item{
  font-size: 0.8rem !important;
}
.w-fit{
  width: fit-content
}

.v-list-group__header,  .v-list-group--sub-group .v-list-group__header
{
  padding-left: 0px !important;
}


.v-list-group--sub-group , .v-list-group--sub-group , .v-application--is-ltr .v-list-group--sub-group .v-list-item__icon:first-child
{
  margin-right: 2px !important;
 
}

.screen-height{
  max-height: 87%;
}

.v-list-item::after {
  content: none !important
}

</style>