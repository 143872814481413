<template>
  <div class="radius-on">
    <div class="d-flex mb-1 align-center">
      <span v-if="selectAll">{{finalItems.length}} item(s) selected</span>
      <span v-else-if="selectItem.length > 0">{{selectItem.length}} item(s) selected</span>
      <v-spacer></v-spacer>
      <template class="display-ib">
        <slot name="additionalinfo" :value="selectItem" :selected="selectItem" :selectall="selectAll"></slot>
      </template>
      <template v-if="showFilter.filter(x => x === true).length > 0">
        <v-tooltip bottom content-class="tooltip-bottom">
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon small v-bind="attrs" v-on="on" color="" @click="resetFilters()">
              <v-icon>mdi-filter-remove</v-icon>
            </v-btn>
          </template>
          <span>Reset Filters</span>
        </v-tooltip>
      </template>
      <template v-if="selectHeader.length > 0">
        <v-menu
          offset-y
          transition="slide-y-transition"
          :close-on-content-click="false"
          scrollable
          max-height="250"
          max-width="300"
          
        >
          <template v-slot:activator="{ on: menu, attrs }">
            <v-tooltip bottom :content-class="items.length < perpage ? 'tooltip-bottom-right' : 'tooltip-bottom'">
              <template v-slot:activator="{ on: tooltip }">
                <v-btn
                  class="mx-0 px-0"
                  icon
                  v-bind="attrs"
                  v-on="{ ...tooltip, ...menu }"
                >
                  <v-icon>mdi-format-list-checks</v-icon>
                </v-btn>
              </template>
              <span>Select Columns</span>
            </v-tooltip>
          </template>
          <div class="pt-2 white">
            <div class="pa-1">
              <v-checkbox
                v-for="(option, k) in selectHeader"
                :key="k"
                color="caccounting"
                class="pa-2 mt-0 pt-0"
                dense
                multiple
                scrollable
                :label="option.text"
                :value="option.value"
                hide-details
                v-model="visibleheader"
              />
            </div>
          </div>
        </v-menu>
      </template>
      <div class="radius-on border-on" v-if="pagecount > 1">
        <v-btn icon small :disabled="page === 1" @click="page--">
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
        <span class="mx-1">Page {{page}} of {{pagecount}}</span>
        <v-btn icon small :disabled="page === pagecount" @click="page++">
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
      </div>
    </div>
    <v-simple-table :class="`${(bgColor)?'FC-Table border-on':'v-table-color'}` "  dense class=" radius-all">
      <template>
        <thead>
          <tr>
            <th  v-if="multiselectkey" id="dd" class="pl-2">
              <v-checkbox
                dense
                class="ma-0 py-1"
                hide-details
                v-model="selectAll"
                @click="select()"
              ></v-checkbox>
            </th>
            <template v-for="(header, index) in headers">
              <th 
                v-if="
                  (header.lock ?? true) ||
                  visibleheader.indexOf(header.value) > -1
                "
                :key="header.value"
                :sortable="header.sortable"
                :class="header.alignment"
                id="header"
              >
                <div>
                  {{ header.text }}
                  <div class="d-inline-block" v-if="header.sortable || header.filterable">
                    <div class="d-flex">
                      <span v-if="header.sortable" @click="sortData(header)">
                        <v-btn
                          icon
                          x-small
                          v-if="!sortDesc && sortKey === header.value"
                        >
                          <v-icon>mdi-arrow-up</v-icon>
                        </v-btn>
                        <v-btn
                          icon
                          x-small
                          v-else-if="sortKey === header.value"
                        >
                          <v-icon>mdi-arrow-down</v-icon>
                        </v-btn>
                        <v-btn
                          icon
                          x-small
                          v-else
                          class="grey--text text--lighten-1"
                        >
                          <v-icon>mdi-arrow-up-down</v-icon>
                        </v-btn>
                      </span>
                      <v-menu
                        v-if="header.filterable"
                        max-width="300"
                        offset-y
                        :close-on-content-click="false"
                        :key="index"
                        rounded="lg"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn icon x-small v-bind="attrs" v-on="on">
                            <v-icon
                              x-small
                              v-if="!showFilter[index]"
                              class="grey--text text--lighten-1"
                            >
                              mdi-filter
                            </v-icon>
                            <v-icon x-small v-else>mdi-filter-menu </v-icon>
                          </v-btn>
                        </template>
                        <v-card max-height="300">
                          <v-card-text class="pa-0">
                            <lb-string
                              class="px-2 pt-2 mt-0"
                              label="Search"
                              v-model="seachFilter[header.value]"
                              hidedetails
                            />
                          </v-card-text>
                          <div
                            class="mt-2 ml-3 d-flex"
                            v-if="filterValue[header.value]?.length > 0"
                          >
                            {{ filterValue[header.value]?.length }} Selected
                            Items
                            <v-spacer></v-spacer>
                            <div class="mr-8">
                              <v-btn
                                class=""
                                x-small
                                @click="
                                  filterValue[header.value] = {};
                                  showFilter[index] = false;
                                "
                              >
                                Clear Selections
                              </v-btn>
                            </div>
                          </div>
                          <v-card-text class="pa-0 ma-0 pt-2 scroll">
                            <v-checkbox
                              v-for="(option, k) in getFilterList(
                                seachFilter[header.value],
                                header.value
                              ).slice(0,25)"
                              :key="k"
                              class="pa-2 mt-0 pt-0"
                              dense
                              multiple
                              scrollable
                              :label="option.name"
                              :value="option.value"
                              hide-details
                              v-model="filterValue[header.value]"
                              @change="applyFilters"
                            />
                            <div v-if="getFilterList(
                                seachFilter[header.value],
                                header.value
                              ).length > 25" class="mx-2 my-1 grey--text caption">{{getFilterList(
                                seachFilter[header.value],
                                header.value
                              ).length - 25}} more</div>
                          </v-card-text>
                        </v-card>
                      </v-menu>
                    </div>
                  </div>
                </div>
              </th>
            </template>
          </tr>
        </thead>
        <tbody>
          <template v-for="item in pageItems">
            <tr :key="item.id">
              <td v-if="multiselectkey" :class="[item.status === false ? 'border-left-error': 'border-left-transparent']">
                <v-checkbox
                  dense
                  class="ma-0 py-1"
                  hide-details
                  :value="item[multiselectkey] || item['_id']"
                  v-model="selectItem"
                  @click="removeAllSelect()"
                ></v-checkbox>
              </td>
              <template v-for="(v, k) in headers">
                <td 
                  v-if="(v.lock ?? true) || visibleheader.indexOf(v.value) > -1"
                  :key="k"
                  @click.stop="((typeof item.__click === 'string') ? $nova.gotoLink({ path: item.__click }) : ((typeof item.__click === 'function') ? item.__click(item) : null))"
                  :class="[v.alignment, item.__click ? 'cursor-pointer' : '', ]"
                >
                  <div>
                    <div v-if="enableslot.indexOf(v.value) > -1">
                      <slot :name="v.value" :item="item"></slot>
                    </div>
                    <div v-else>
                      <span v-if="v.datatype === 'date'">
                        {{ $nova.formatDate(item[v.value]) }}
                      </span>
                      <span v-else-if="v.datatype === 'number'">
                        {{ $nova.formatNumber(item[v.value]) }}
                      </span>
                      <span v-else-if="v.datatype === 'array'">
                        <div v-for="(value, key) in item[v.value]" :key="key">
                          <v-chip x-small> {{ value }}</v-chip>
                        </div>
                      </span>
                      <span v-else-if="v.datatype === 'object'">
                        <div v-for="(value, key) in item[v.value]" :key="key">
                          <v-chip x-small> {{ value }}</v-chip>
                        </div>
                      </span>
                      <span v-else>
                        {{ item[v.value] }}
                      </span>
                    </div>
                  </div>
                </td>
              </template>
            </tr>
          </template>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
export default {
  props: {
    headers: {
      type: Array,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    multiselectkey: {
      type: String,
      default: "",
    },
    slots: {
      type: Array,
      required: false,
    },
    group: {
      type: String,
      default: null,
    },
    enableslot: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: true,
    },
    sortByDate:{
      type: Boolean,
      default: false,
    },
    bgColor:{
      type : Boolean,
      default : false,
    },
    perpage:{
      type:Number,
      default:50,
    }
  },
  data() {
    return {
      sortKey: "",
      sortDesc: false,
      filterValue: {},
      filterItems: {},
      showFilter: [],
      visibleheader: [],
      seachFilter: {},
      finalyHead: null,
      selectAll: false,
      selectItem: [],
      page: 1,
      pagecount: 1,
      total: 0
    };
  },
  created() {
    if (this.loading === false) {
      // console.log("create reset called");
      this.resetFilters();
    }
  },
  activated() {
    if (this.loading === false) {
      // console.log("create reset called");
      this.resetFilters();
    }
  },
  computed: {
    selectHeader() {
      return this.headers.filter((x) => {
        if (x.lock !== undefined && !x.lock) {
          return {
            value: x.value,
            text: x.text,
          };
        }
      });
    },
    pageItems() {
      this.storeTotal((this.finalItems || []).length)
      return this.finalItems.slice((this.page-1)*this.perpage, (this.page)*this.perpage);
    },
    finalItems() {
      let itemFinal = "";
      if (Object.keys(this.filterValue).length === 0) {
        itemFinal = this.items;
      }
      itemFinal = (this.items || []).filter((item) => {
        return this.headers.every((header) => {
          const value = header.value;
          const data = item[value];
          const search = this.filterValue[value];
          if (!search) {
            return true;
          } else if (Array.isArray(data)) {
            return this.includesValue(data, search);
          } else if (typeof data === "object") {
            return this.jsonIncludesValue(header.filterkey, data, search);
          } else return this.includesValue(data, search);
        });
      });
      let items = itemFinal;
      if (this.sortKey) {
        const itemsWithSortKey = items.filter(item => this.sortKey in item && item[this.sortKey] !== undefined);
        const itemsWithoutSortKey = items.filter(item => !(this.sortKey in item) || item[this.sortKey] === undefined);
        itemsWithSortKey.sort((a, b) => {
          const modifier = this.sortDesc ? -1 : 1;
          const valueA = typeof a[this.sortKey] === 'string'? a[this.sortKey]?.toLowerCase(): a[this.sortKey];
          const valueB = typeof b[this.sortKey] === 'string'? b[this.sortKey]?.toLowerCase(): b[this.sortKey];
          if (valueA < valueB) return -1 * modifier;
          if (valueA > valueB) return 1 * modifier;
          return 0;
        });
        items = itemsWithSortKey.concat(itemsWithoutSortKey);
      }
      for (let i = 0; i < items.length; i++) {
        const el = items[i];
        el.indexno = i+1;
      }
      this.storeTotal((items || []).length);
      this.resetPage();
      return items;
    },
  },
  methods: {
    resetPage(){
      this.page = 1;
    },
    storeTotal(len){
      this.total = len;
      this.selectItem = [];
      this.selectAll = false;
      this.pagecount = Math.ceil(this.total/this.perpage);
    },
    getFilterList(search = "", key = "") {
      if (search) {
        return this.filterItems[key].filter((x) => {
          if (
            (x.name || "").toLowerCase().includes((search || "").toLowerCase())
          )
            return x || [];
        });
      } else return this.filterItems[key] || [];
    },
    headerFilter() {
      this.filterItems = {};
      for (const head of this.headers) {
        if (head.filterable === true) {
          this.filterItems[head.value] = this.getUniqueColumnValues(head);
        }
        if (head.defaultsort && head.defaultsort === 'desc') {
          this.sortKey = head.value;
          this.sortDesc = true;
        }
      }
      if(this.sortDesc === true){
        this.sortData();
      }
    },
    populateVisible() {
      for (const k in this.headers) {
        if (Object.hasOwnProperty.call(this.headers, k)) {
          const el = this.headers[k];
          if (!el.lock && el.visible) this.visibleheader.push(el.value);
        }
      }
    },
    includesValue(value, search) {
      if (search.length > 0) {
        if (search instanceof Array) {
          for (const arrayItem of search) {
            if (typeof arrayItem === "string") {
              if (value instanceof Array) {
                return this.hasMatchingValue(value, search);
              } else if (typeof value === "number") {
                let nvalue = value.toString();
                return search.includes(nvalue);
              } else {
                let nsearch = search.map((x) => x.toLowerCase());
                let nvalue = (value || "").toLowerCase();
                return nsearch.includes(nvalue);
              }
            } else {
              return search.includes(value);
            }
          }
        }
      }
      return true;
    },
    jsonIncludesValue(filterKey, value, search) {
      if (value && typeof value === "object") {
        if (Object.prototype.hasOwnProperty.call(value, filterKey)) {
          return this.includesValue(value[filterKey], search);
        }
        return Object.values(value).some((val) =>
          this.includesValue(val, search)
        );
      }
      return false;
    },
    hasMatchingValue(arr1, arr2) {
      console.log(arr1, arr2);
      for (let i = 0; i < arr1.length; i++) {
        if (arr2.includes(arr1[i].toString())) {
          return true;
        }
      }
      return false;
    },
    applyFilters() {
      this.showFilter = this.headers.map((header) => {
        return (
          header.filterable &&
          this.filterValue[header.value] &&
          (this.filterValue[header.value] || []).length > 0
        );
      });
    },
    getUniqueColumnValues(header) {
      let column = header.value;
      let filterkey = header.filterkey;
      if (Array.isArray(header.filterList)) {
        let filterOption = [];
        header.filterList.forEach((val) => {
          if (typeof val === "object" && !(val instanceof Array)) {
            return filterOption.push(val);
          } else {
            let temArr = [];
            for (const item of header.filterList) {
              temArr.push({
                name: item,
                value: item,
              });
            }
            return (filterOption = temArr);
          }
        });
        return filterOption;
      } else {
        let values = new Set();
        (this.items || []).forEach((item) => {
          const value = item[column];
          if (Array.isArray(value)) {
            value.forEach((val) => {
              if (typeof val === "object" && !(val instanceof Array)) {
                Object.keys(val).forEach((key) => values.add(key));
                values.add(String(val[filterkey] || val));
              } else values.add(String(val));
            });
          } else if (typeof value === "object") {
            if (filterkey) {
              values.add(String(value[filterkey] || value));
            } else {
              Object.keys(value).forEach((key) =>
                values.add(String(value[key]))
              );
              //values.add(String(value[filterkey] || value));
            }
          } else {
            values.add(String(value));
          }
        });
        let temArr = [];
        for (const item of Array.from(values)) {
          temArr.push({
            name: item,
            value: item,
          });
        }
        return temArr;
      }
    },
    sortData(header) {
      if (header) {
        if (this.sortKey !== header.value) this.sortDesc = true;
        if (this.sortKey && this.sortDesc && this.sortKey === header.value) {
          this.sortKey = "";
        } else if (this.sortDesc) {
          this.sortKey = header.value;
          this.sortDesc = false;
        } else {
          this.sortKey = header.value;
          this.sortDesc = true;
        }
      }
    },
    resetFilters() {
      this.filterValue = {};
      this.showFilter = [];
      this.sortKey = "";
      if(this.sortByDate){
        this.sortKey = 'created_at'
      }
      this.headerFilter();
    },
    select() {
      this.selectItem = [];

      if (this.selectAll) {
        for (let i in this.finalItems) {
          this.selectItem.push(this.finalItems[i][this.multiselectkey]);
        }
      } else {
        this.selectItem = [];
      }
    },
    removeAllSelect() {
      if(this.selectItem.length === this.finalItems.length) this.selectAll = true;
      else this.selectAll = false;
    },
  },
  watch: {
    loading(d) {
      if (d === false) {
        this.resetFilters();
        this.selectAll = false;
        this.selectItem = [];
      }
    },
  },
};
</script>

<style scoped>
.scroll {
  overflow-x: auto;
  max-height: 250px;
  width: 280px;
}
</style>