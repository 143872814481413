<template>
    <div>
        <v-tooltip bottom content-class="tooltip-bottom">
            <template v-slot:activator="{ on, attrs }">
                <v-btn class="pa-0 mt-1" name="conversations" v-bind="attrs" v-on="on" text small
                    @click="conversationList = []; getProjectCon(true)">
                    <v-badge color="error" offset-x="10" offset-y="10" top dot :value="notify">
                        <v-icon class="pr-2" :color="highlight ? 'blue' : 'gray'">mdi-comment-text-multiple</v-icon>
                    </v-badge>
                    <span v-if="showname" class="text-capitalize">Comments</span>
                </v-btn>
            </template>
            <span>Comments</span>
        </v-tooltip>


        <lb-drawer v-model="dialog" :heading="heading" persistent :dailogboxOnly="true">
            <template v-slot:body>
                <div style="max-height:70vh; overflow:scroll" ref="chatContainer">
                    <div class="" ref="chatContainer">
                        <div v-for="(item, index) in conversationList" :key="index">
                            <!-- you -->
                             
                             <div class="d-flex align-center  mt-3"  v-if="item.user == $store.state.user.id">
                                <!-- <v-card class="shadow-off pa-0 ma-0" color="lightgray">
                                    <v-card-text class="pa-1">
                                        <v-btn icon small> <v-icon>mdi-emoticon-excited-outline</v-icon> </v-btn>
                                        <v-btn icon small> <v-icon>mdi-pencil-outline</v-icon> </v-btn>
                                        <v-btn icon small> <v-icon>mdi-dots-horizontal</v-icon> </v-btn>
                                    </v-card-text>
                                </v-card> -->
                                 <v-tooltip bottom v-if="(conversationList[index - 1] || {}).user !== item.user">
                                     <template v-slot:activator="{ on, attrs }">
                                         <v-avatar class="ml-2" size="30" elevation="10" v-bind="attrs" v-on="on" color="indigo">
                                             <!-- <v-img v-if="item.user_detail.profile_url && showprofile" alt="Avatar"
                                                 width="30px" height="30" :size="30"
                                                 :src="item.user_detail.profile_url"></v-img> -->
                                             <span class="white--text">{{ item.user_detail.name[0] }}</span>
                                         </v-avatar>
                                     </template>
                                     <span>{{ item.user_detail.name }}</span>
                                </v-tooltip>
                                    
                                <span v-else class="mr-9"></span>
                                    
                                 
                             </div>
                            <div   class="mb-2 d-flex flex-column all-width justify-end"
                                v-if="item.user == $store.state.user.id">
                                    <div class="timeFont mr-8 text-right mt-0">
                                        {{
                                            $nova.formatDateTime(item.created_at, "DD MMM yyyy hh:mm A")
                                        }}
                                    </div>
                                    <v-card  class="mr-8 shadow-off radius-small"
                                         color="purpleborder">
                                        <v-card-text class="shadow-off">
                                            <div class="black--text" :id="'sent-chat-message-' + index">{{ item.value }}</div>
                                        </v-card-text>
                                    </v-card>
    
                            </div>
                            <!-- sender -->
                            <div   class="mt-2" v-else>
                                <div class="d-flex align-center " v-if="(conversationList[index - 1] || {}).user !== item.user">
                                    <v-tooltip bottom >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-avatar dark class="" size="30"  v-bind="attrs" v-on="on" :color="item.user_detail._id == $store.state.user.id
                                                ? 'indigo'
                                                : 'indigo'
                                                ">
                                                <v-img v-if="item.user_detail.profile_url && showprofile" alt="Avatar"
                                                    width="30px" height="30" :size="30"
                                                    :src="item.user_detail.profile_url"></v-img>
                                                <span class="white--text" v-else>{{ item.user_detail.name[0] }}</span>
                                            </v-avatar>
                                        </template>
                                        <span>{{ item.user_detail.name }}</span>
                                    </v-tooltip>
                                    <span class="ml-2">{{ item.user_detail.name }}</span>
                                </div>
                                <div v-else class="ml-9"></div>
    
                                <div class="mb-2 d-flex flex-column all-width justify-start">
                                    <div class="ml-8">
                                        {{
                                            $nova.formatDateTime(item.created_at, "DD MMM yyyy hh:mm A")
                                        }}
                                    </div>
                                    <v-card class="ml-8 radius-small shadow-off" color="lightgray">
                                        <v-card-text >
                                            <div class="" :id="'receive-chat-message-' + index">{{ item.value }}</div>
                                        </v-card-text>
                                    </v-card>
                                </div>
                            </div>
                        </div>
                        <div v-if="(conversationList || []).length === 0"
                            class="d-flex flex-column align-center justify-center  all-height">
                            <v-spacer></v-spacer>
                            <p class="text-subtitle-1 grey--text">Be the first one to comment</p>
                            <v-spacer></v-spacer>
                        </div>
                    </div>
                </div>
            </template>
            <template v-slot:actions v-if="!closed">
                <div class="d-flex align-center mb-1" v-if="recurring">
                    <v-checkbox label="Reucurring Comment" class="mr-2 pa-0 ma-0" dense hide-details
                        v-model="recurringMsg"></v-checkbox>
                    <v-tooltip right content-class="tooltip-right">
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon color="blue-grey lighten-2" dark v-bind="attrs" v-on="on">
                                mdi-help-circle-outline
                            </v-icon>
                        </template>
                        <span class="text-justify">{{ recurringtext }}</span>
                    </v-tooltip>
                </div>
                <div class="d-flex align-center all-width">
                    <lb-string placeholder="Type your message here" label="" hidedetails @enter="sendQuery()"
                        v-model="queryMsg" :isRequired="true" />
                    <v-btn small color="caccounting" class="ml-3 pa-5" :loading="loading" @click="sendQuery()">
                        <v-icon color="white">mdi-send</v-icon></v-btn>
                </div>
            </template>
        </lb-drawer>
    </div>
</template>
<script>
export default {
    name: "conversations",
    data() {
        return {
            dialog: false,
            loading: false,
            conversationList: [],
            queryMsg: "",
            recurringMsg: false,
        };
    },
    props: {
        getapi: {
            type: String,
            default: "",
        },
        sendapi: {
            type: String,
            default: "",
        },
        readapi: {
            type: String,
            default: "",
        },
        heading: {
            type: String,
            default: "Conversation",
        },
        recurring: {
            type: Boolean,
            default: false,
        },
        recurringtext: {
            type: String,
            default: "Requirements comments for next time",
        },
        notify: {
            type: Boolean,
            default: false,
        },
        width: {
            type: String,
            default: "800",
        },
        type: {
            type: String,
            default: "string",
        },
        closed: {
            type: Boolean,
            default: true,
        },
        highlight: {
            type: Boolean,
            default: false
        },
        showprofile: {
            type: Boolean,
            default: true
        },
        showname:{
            type:Boolean,
            default: false,
        }
    },
    methods: {
        sendQuery() {
            if (this.queryMsg) {
                this.loading = true;
                let dataObject = {
                    type: this.type,
                    value: this.queryMsg,
                    status: this.status,
                };
                // console.log(dataObject);
                let successflag = false;
                this.axios
                    .post(this.sendapi, { data: dataObject })
                    .then((dt) => {
                        if (dt.data.status === "success") {
                            successflag = true;
                            // this.$store.commit("sbSuccess", "successfully sended");
                            this.queryMsg = "";

                        } else throw Error(dt.data.message || "Unknown error")
                    })
                    .catch((err) => {
                        this.$store.commit("sbError", err.message || err || "Unknown error!");
                        console.log(err);
                    })
                    .finally(() => {
                        this.loading = false;
                        if (successflag) this.getProjectCon();
                    });
            }
        },
        getProjectCon(mark = false) {
            this.dialog = true;
            this.loading = true;
            this.axios
                .post(this.getapi)
                .then((dt) => {
                    if (dt.data.status === "success") {
                        this.conversationList = dt.data.data;
                        this.dialog=true                        
                        if (mark) this.markAsRead();
                    } else throw Error(dt.data.message || "Unknown error")
                    // console.log(this.conversationList);
                })
                .catch((err) => {
                    this.$store.commit("sbError", err.message || err || "Unknown error!");
                    console.log(err);
                })
                .finally(() => {
                    this.loading = false;
                    this.scrollToBottom();
                });
        },
        markAsRead() {
            this.axios
                .post(this.readapi)
                .then((dt) => {
                    if (dt.data.status === "success") {
                        this.$emit("markread");
                    } else throw Error(dt.data.message || "Unknown error")
                    // console.log(this.conversationList);
                })
                .catch((err) => {
                    console.log(err);
                })
                .finally(() => {
                });
        },
        scrollToBottom() {
            this.$nextTick(() => {
                const chatContainer = this.$refs.chatContainer;
                if (chatContainer) {
                    chatContainer.scrollTop = chatContainer.scrollHeight;
                }
            });
        }
    },
    mounted() {
        // Auto-scroll to the bottom when the component is mounted
        this.scrollToBottom();
    }
};
</script>
