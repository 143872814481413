<template>
  <div>
    <div @click.stop="dialog = true">
      <v-text-field
        ref="element"
        type=""
        prepend-inner-icon="mdi-calendar-range"
        dense
        outlined
        :label="label || 'Date'"
        :clearable="clearable"
        :error-messages="error"
        class=""
        :value="formattedRangevalue"
        @keypress="dialog = true"
        @click:clear.stop="
          content = '';
          tempContent = '';
          updateValue();
        "
        :hide-details="hidedetails"
        :disabled="disabled"
        :placeholder="placeholder"
      ></v-text-field>
    </div>
    <v-dialog max-width="290px" v-model="dialog" transition="dialog-transition">
      <v-card>
        <v-card-text class="pa-0">
          <v-date-picker
            elevation="0"
            v-model="tempContent"
            color="primary"
            :min="min"
            :max="max"
            show-adjacent-months
            :type="type"
            :readonly="readonly"
          >
            <v-spacer></v-spacer>
            <v-btn text small @click="dialog = false">close</v-btn>
            <v-btn
              small
              elevation="0"
              color="primary"
              @click="
                content = tempContent;
                dialog = false;
                updateValue();
              "
              >ok</v-btn
            >
          </v-date-picker>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  activated() {
    this.setValue();
    this.updateValue();
  },
  created() {
    // console.log(this.mindate);
    this.setValue();
    this.updateValue();
  },
  data: () => ({
    formattedvalue: "",
    dialog: false,
    content: "",
    tempContent: "",
  }),
  props: {
    error: {
      type: [String, Array],
    },
    label: {
      type: String,
    },
    value: {
      type: String,
    },
    min: {
      type: String,
      default: undefined,
    },
    max: {
      type: String,
      default: undefined,
    },
    type: {
      type: String,
      default: "date",
    },
    hidedetails: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readonly:{
      type: Boolean,
      default: false,
    },
    clearable:{
      type:Boolean,
      default: true,
    },
    placeholder:{
      type:String,
      default:''
    }
    // required: {
    //   type: Boolean,
    //   default: false
    // }
  },
  methods: {
    setValue: function () {
      this.content = this.$nova.formatDateISO(this.value);
      this.tempContent = this.$nova.formatDateISO(this.value);
      this.updateValue();
    },
    updateValue: function () {
      this.$emit("input", this.content);
      this.$emit("change", this.content);
    },
    setFocus: function () {
      this.$nextTick(() => {
        this.$refs.element.$el.querySelector("input").focus();
      });
    },
    setBlank: function () {
      this.content = "";
      this.tempContent = "";
    },
  },
  computed: {
    formattedRangevalue() {
      let format = undefined;
      if(this.type === "month") format = "MMM YYYY"
      let v = this.$nova.formatDate(this.content, format);
      if (v !== "") return v;
      else {
        this.setBlank();
        return "";
      }
    },
  },
  watch: {
    value: function () {
      this.setValue();
    },
  },
};
</script>