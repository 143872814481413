<template>
  <v-text-field ref="element" :step="step" :style="`max-width: ${width} !important`" outlined dense type="number" :label="label" :clearable="clearable" :min="min || ''" :max="max || ''"
    :error-messages="error" :prepend-inner-icon="prependiconinner" :append-icon="appendiconinner" v-model="displayContent" @click:clear="content = '';tempContent=''" @input="updateValue()" :hide-details="hidedetails" :disabled="disabled"
    :placeholder="placeholder" :rules="rules"
  ></v-text-field>
</template>

<script>
  export default {
    activated() {
      this.setValue();
      this.updateValue();
    },
    created() {
      this.setValue();
      this.updateValue();
    },
    data: () => ({
      content: "",
      tempContent: 0.00000000000001
    }),
    props: {
      error: {
        type: [String, Array],
      },
      appendiconinner: {
        type: String,
        default: ""
      },
      prependiconinner: {
        type: String,
        default: ""
      },
      label: {
        type: String,
        default: "Number"
      },
      step: {
        type: String,
        default: "1"
      },
      value: {
        type: [String, Number],
        // type: String,
        default: 0
      },
      min: {
        type: String,
        default: ""
      },
      max: {
        type: [String, Number],
        default: ""
      },
      width: {
        type: String,
        default: ""
      },
      hidedetails: {
        type: Boolean,
        default: false
      },
      disabled: {
        type: Boolean,
        default: false
      },
      clearable: {
        type: Boolean,
        default: false
      },
      ignoredFieldDefaultValue:{
        type:Number,
        default:0.00000000000001
      },
      placeholder:{
        type:String,
        default:''
      },
      rules:{
        type:Array,
        default: () => [
            
          ]
      }
    },
    methods: {
      setValue: function(){
        let v = this.checkRules(this.value.toString()).toString();
        if(v == this.ignoredFieldDefaultValue){
          this.tempContent = "";
        }else{
        this.tempContent = v;
        }
        this.content = Number(v) ?? v;
      },
      updateValue: function () {
        if(this.tempContent === ''){
          this.tempContent = this.ignoredFieldDefaultValue.toString();
        }
        this.tempContent = this.checkRules(this.tempContent).toString();
        if(isNaN(Number(this.tempContent))) this.tempContent = this.checkRules(this.content).toString();
        else this.content = Number(this.tempContent) ?? this.tempContent;
        this.$emit('input', this.content);
        this.$emit('change', this.content);
      },
      setFocus: function(){
        this.$nextTick(() => {
          this.$refs.element.$el.querySelector('input').focus();
        });
      },
      checkRules: function(x){
        if(x){
          let v = Number(x)
          if(this.min && !isNaN(Number(this.min))) if(v < Number(this.min)) v = this.min;
          if(this.max && !isNaN(Number(this.max))) if(v > Number(this.max)) v = this.max;
          return v;
        }else return "";
      },
    },
    computed:{
      displayContent: {
        get() {
          return this.tempContent == 0.001 ? '' : this.tempContent;
        },
        set(value) {
          this.tempContent = value;
        }
      }
    },
    watch: {
      value: function(){
        this.setValue();
      },
    }
  }
</script>